import { AnswersData, LOCAL_STORAGE_KEY, TabKey } from '../../types/questionsTypes'
import createCheckin from './createCheckin'

function sideEffects(status:TabKey, answers:AnswersData, venueId:string){

	switch (status){
		
		case ('preApproved'):
		case ('approved'):{
			if (venueId){
				createCheckin(answers, 'approved', venueId)
			}
			
			const token:AnswersData = {
				...answers,
				lastCheckinDate: new Date(),
				lastCheckinStatus: 'approved'
			}
			localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(token))
			break
		}
		
		case ('rejected'):{
			if (venueId){
				createCheckin(answers, 'rejected', venueId)
			}
		}
	}
}

export default sideEffects
