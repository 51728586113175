import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './components/Main/Main'

function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path='/Venue/:venueId' component={Main}/>
					<Route path='/' component={Main}/>
				</Switch>
			</Router>
		</div>
	)
}

export default App
