import React from 'react'
import Panel from '../../Panel/Panel'
import { FreeButton } from 'react-ui-scaffold'
import useQuestions from '../../../hooks/useQuestions/useQuestions'

export default function HasContact() {
	
	const { next } = useQuestions()
	
	return (
		<Panel className='HasContact'>
			<div className="content">
				<h2>Have you come into contact with someone who:</h2>
				<p>
					<ul>
						<li>Experienced COVID-19 symptoms in the past 2 weeks?</li>
						<li>Tested positive in the past 2 weeks?</li>
					</ul>
					
				</p>
			</div>

			<div className="controls yesNo">
				<FreeButton
					className='positive'
					onClick={() => next(true)}
				>Yes</FreeButton>

				<FreeButton
					className='negative'
					onClick={() => next(false)}
				>No</FreeButton>
			</div>
		</Panel>
	)
}
