import dayjs from 'dayjs'
import { AnswersData, TabKey } from '../../types/questionsTypes'

const CHECKIN_FREQUENCY = 3 //number of days before requiring a new checkin

function nextQuestion(activeTab:TabKey, answers:AnswersData):TabKey{
	
	switch (activeTab){
		case ('contactInfo'): {
			//check if they already had a successful checkin in the last few days
			const prevCheckin = answers.lastCheckinDate && dayjs(answers.lastCheckinDate).isBefore(dayjs().add(CHECKIN_FREQUENCY, 'days'))
			if (prevCheckin && answers.lastCheckinStatus === 'approved'){
				return 'preApproved'
			}
			
			if (answers.doubleVaccinated){
				return 'preApproved'
			}
			
			return 'doubleVaccinated'
		}
		
		case ('doubleVaccinated'): {
			if (answers.doubleVaccinated){
				return 'approved'
			}
			return 'hasSymptoms'
		}
		
		case ('hasSymptoms'): {
			if (answers.hasSymptoms){
				return 'rejected'
			}
			return 'travelled'
		}
		
		case ('travelled'):{
			if (answers.travelled){
				return 'rejected'
			}
			return 'hasContact'
		}
		
		case ('hasContact'):{
			if (answers.hasContact){
				return 'rejected'
			}
			
			return 'approved'
		}
	}
	
	return activeTab
}

export default nextQuestion