import React, { createContext, ReactNode } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { defaultVenue, VenueData, VenueHook } from '../../types/venueTypes'
import fetchVenue from './fetchVenue'

const VenueContext = createContext<VenueHook>({ isLoading: false, venue: defaultVenue })

//Provider
interface Props{
	children: ReactNode,
	venueId?: string
}

export function VenueProvider({ children, venueId }:Props){
	
	const [venue, setVenue] = useState<VenueData>(defaultVenue)
	const [isLoading, setIsLoading] = useState(true)
	
	useEffect(() => {
		setIsLoading(true)
		if (venueId){
			fetchVenue(venueId).then((v:VenueData) => {
				setIsLoading(false)
				setVenue(v)
			}).catch(err => {
				setIsLoading(false)
				console.log(err)
			})
		} else {
			setIsLoading(false)
			setVenue(defaultVenue)
		}
		
	}, [venueId])
	
	return <VenueContext.Provider value = {{ venue, isLoading }}>
		{children}
	</VenueContext.Provider>
}

//Hook
function useVenue():VenueHook{
	return useContext(VenueContext)
}

export default useVenue
