import React from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useVenue from '../../hooks/useVenue/useVenue'

export default function RestaurantButton() {
	
	const { venue, isLoading } = useVenue()
	const { menuLink, menuButtonText } = venue
	
	if (!(menuButtonText && menuButtonText)){
		return null
	}
	
	return (
		<a href={menuLink} target="_blank" rel="noreferrer">
			<FreeButton
				className='cion'
				loading = {isLoading}
			>
				{menuButtonText || 'Restaurant Menu'}
			</FreeButton>
		</a>
		
	)
}
