import React from 'react'
import Footer from '../../components/Footer/Footer'
import { QuestionsProvider } from '../../hooks/useQuestions/useQuestions'
import Navbar from '../../components/Navbar/Navbar'
import Questions from '../../components/Questions/Questions'
import { VenueProvider } from '../../hooks/useVenue/useVenue'

function Main({ match }:any) {
	return (
		<VenueProvider venueId={match.params.venueId}>
			<QuestionsProvider>
				<Navbar />
				<Questions />
				<Footer />
			</QuestionsProvider>
		</VenueProvider>
	)
}

export default Main
