import React from 'react'
import './Panel.css'

export default function Panel({ children, className }:any) {
	return (
		<div className={`Panel ${className}`}>
			{children}
		</div>
	)
}
