import React from 'react'
import { useState } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useQuestions from '../../../hooks/useQuestions/useQuestions'
import Panel from '../../Panel/Panel'

export default function ContactInfo() {
	
	const { setAnswer, next, answers } = useQuestions()
	const [message, setMessage] = useState('')
	
	const changeHandler = (e:any) => {
		setAnswer('contactInfo', {
			...answers.contactInfo,
			[e.target.name]: e.target.value
		})
	}
	
	const submitHandler = () => {
		setMessage('')
		//#TODO proper validation
		if (answers.contactInfo.name && answers.contactInfo.number && answers.contactInfo.email){
			return next()
		}
		
		setMessage('Please fill out all fields')
	}
	
	return (
		<Panel className={'ContactInfo'}>
			<h1>Contact Info</h1>
			
			<div className="message">
				{message}
			</div>

			<input
				className="input wide"
				placeholder='Full Name'
				name='name'
				value={answers.contactInfo.name}
				onChange={changeHandler}
			/>

			<input
				className="input wide"
				name='number'
				placeholder='Phone Number'
				value={answers.contactInfo.number}
				onChange={changeHandler}
			/>

			<input
				className="input wide"
				name='email'
				placeholder='Email'
				value={answers.contactInfo.email}
				onChange={changeHandler}
			/>

			<FreeButton className="cion" style={{ marginTop: '20px' }} onClick={submitHandler}>
				Next
			</FreeButton>
			
		</Panel>
	)
}
