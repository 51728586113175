import beam from '../../beam'
import { AnswersData } from '../../types/questionsTypes'

export default async function createCheckin(answers:AnswersData, status:string, venueId:string){
	try {
		await beam.post('/api/checkin', { answers, status, venueId })
	} catch (err){
		console.log(err)
	}
}
