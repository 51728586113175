import React from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useQuestions from '../../../hooks/useQuestions/useQuestions'
import Panel from '../../Panel/Panel'

export default function Travelled() {

	const { next } = useQuestions()

	return (
		<Panel className='DoubleVaccinated'>
			<div className="content">
				<h3>Have you travelled outside of Canada within the past 2 weeks?</h3>
			</div>

			<div className="controls yesNo">
				<FreeButton
					className='positive'
					onClick={() => next(true)}
				>Yes</FreeButton>

				<FreeButton
					className='negative'
					onClick={() => next(false)}
				>No</FreeButton>
			</div>
		</Panel>
	)
}
