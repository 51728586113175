import React from 'react'
import './Navbar.css'
import cionLogo from '../../assets/logo.svg'

export default function Navbar() {
	return (
		<div className='nav Navbar flexY'>
			<div className="half navTitle">
				<h2 className="accent">COVID-19</h2>
				Contact Tracing
			</div>
			
			<div className="half branding">
				<div className="poweredBy">
					Powered By
				</div>
				
				<div className="logo">
					<img className='Cion Studio' alt='Cion Studio' src={cionLogo}/>
				</div>
			</div>
		</div>
	)
}
