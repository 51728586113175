import React from 'react'
import logo from '../../assets/logo-black.svg'
import './Footer.css'

export default function Footer() {
	return (
		<div className='Footer'>
			<img alt='Cion Studio' src={logo}/>
		</div>
	)
}
