import React from 'react'
import Panel from '../../Panel/Panel'
import RestaurantButton from '../../RestaurantButton/RestaurantButton'

export default function PreApproved() {
	
	return (
		<Panel className='DoubleVaccinated'>
			<div className="content">
				<h2>Thanks! Bon Appetit!</h2>
				<p>
					{"Since you've recently checked in, you are welcome inside the restaurant. Bon appetit!"}
				</p>
			</div>

			<div className="controls">
				<RestaurantButton/>
			</div>
		</Panel>
		
	)
}
