export interface AnswersData {
	contactInfo: {
		name: string,
		number: string,
		email: string,
	},
	
	lastCheckinDate?: Date,
	lastCheckinStatus?: 'approved' | 'rejected',
	doubleVaccinated?: boolean,
	hasSymptoms?: boolean,
	travelled?: boolean,
	hasContact?: boolean,
}

export type QuestionKey = 'contactInfo' |
'contactInfo' |
'doubleVaccinated' |
'hasSymptoms' |
'travelled' |
'hasContact'

export type TabKey = QuestionKey | 'preApproved' | 'approved' | 'rejected'

export interface QuestionsProviderData {
	answers: AnswersData,
	setAnswer(question: QuestionKey, value: any): void,
	activeTab: TabKey,
	setActiveTab(key:TabKey): void
	next(answer?:any): void,
	
	lastCheckinStatus?: 'approved' | 'rejected',
	lastCheckinDate?: Date
}

export const LOCAL_STORAGE_KEY = 'CION_COVID_ANSWERS_v1'
