import React from 'react'
import './Questions.css'
import { ControlledTabContainer, InfoTab } from 'react-ui-scaffold'
import useQuestions from '../../hooks/useQuestions/useQuestions'
import ContactInfo from './Support/ContactInfo'
import DoubleVaccinated from './Support/DoubleVaccinated'
import Rejected from './Support/Rejected'
import Approved from './Support/Approved'
import HasSymptoms from './Support/HasSymptoms'
import Travelled from './Support/Travelled'
import HasContact from './Support/HasContact'
import PreApproved from './Support/PreApproved'
import { Page } from 'react-ui-scaffold'
import useVenue from '../../hooks/useVenue/useVenue'

export default function Questions() {
	const { activeTab } = useQuestions()
	const { isLoading } = useVenue()
	
	return (
		<Page className='Questions flexCenter' loading={isLoading}>
			<ControlledTabContainer activeTab={activeTab}>
				<InfoTab tabID='contactInfo'><ContactInfo/></InfoTab>
				<InfoTab tabID='doubleVaccinated'><DoubleVaccinated/></InfoTab>
				<InfoTab tabID='hasSymptoms'><HasSymptoms/></InfoTab>
				<InfoTab tabID='travelled'><Travelled/></InfoTab>
				<InfoTab tabID='hasContact'><HasContact/></InfoTab>
				
				<InfoTab tabID='rejected'><Rejected/></InfoTab>
				<InfoTab tabID='approved'><Approved/></InfoTab>
				<InfoTab tabID='preApproved'><PreApproved/></InfoTab>
			</ControlledTabContainer>
		</Page>
	)
}
