import React from 'react'
import Panel from '../../Panel/Panel'
import RestaurantButton from '../../RestaurantButton/RestaurantButton'

export default function Approved() {

	return (
		
		<Panel className='DoubleVaccinated'>
			<div className="content">
				<h2>Thank You!</h2>
				<p>
					You are welcome inside the venue
				</p>
			</div>

			<div className="controls">
				<RestaurantButton />
			</div>
		</Panel>

	)
}
