import { AnswersData } from '../../types/questionsTypes'

const defaultAnswers:AnswersData = {
	contactInfo: {
		name: '',
		number: '',
		email: '',
	},
	
	lastCheckinDate: undefined,
	lastCheckinStatus: undefined,
	doubleVaccinated: undefined,
	hasSymptoms: undefined,
	travelled: undefined,
	hasContact: undefined,
}

export default defaultAnswers