import React, { createContext, useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import defaultAnswers from './defaultAnswers'
import nextQuestion from './questionFlow'
import { AnswersData, LOCAL_STORAGE_KEY, QuestionKey, QuestionsProviderData, TabKey } from '../../types/questionsTypes'
import sideEffects from './sideEffects'
import useVenue from '../useVenue/useVenue'

//Context
const QuestionsContext = createContext<any>(null)

//Provider
export function QuestionsProvider({ children }:any){
	const [allAnswers, setAllAnswers] = useState<AnswersData>(defaultAnswers)
	const [activeTab, setActiveTab] = useState<TabKey>('contactInfo')
	const [resetOnChange, setResetOnChange] = useState(false)
	const { venue } = useVenue()
	
	const setAnswer = (question:QuestionKey, value:any) => {
		if (resetOnChange){
			setResetOnChange(false)
			setAllAnswers({
				...defaultAnswers,
				[question]: value
			})
		} else {
			setAllAnswers({
				...allAnswers,
				[question]: value
			})
		}
	}
	
	//Load answers from localstorage on load
	useEffect(() => {
		try {
			const prev:AnswersData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '')
			if (prev?.contactInfo) {
				setAllAnswers({ ...allAnswers, contactInfo: prev.contactInfo })
				setResetOnChange(true)
			}
		} catch (err){
			console.log(err)
		}
	},[])
	
	//Next question
	const next = (answer?:any) => {

		if (answer !== undefined){
			const newAnswers = {
				...allAnswers,
				[activeTab]: answer
			}
			setAllAnswers(newAnswers)
			const newTab = nextQuestion(activeTab, newAnswers)
			sideEffects(newTab, newAnswers, venue?._id)
			return setActiveTab(newTab)
		}
		
		const newTab = nextQuestion(activeTab, allAnswers)
		sideEffects(newTab, allAnswers, venue?._id)
		return setActiveTab(newTab)
	}
	
	return <QuestionsContext.Provider value={{ answers: allAnswers, setAnswer, activeTab, setActiveTab, next }}>
		{children}
	</QuestionsContext.Provider>
}

//Hook
function useQuestions():QuestionsProviderData{
	return useContext(QuestionsContext)
}

export default useQuestions
