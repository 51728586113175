export interface VenueHook{
	venue: VenueData,
	isLoading: boolean
}

export interface VenueData{
	_id: string,
	menuLink: string,
	menuButtonText: string,
	name: string,
	address: string
}

export const defaultVenue:VenueData = { _id: '', name: '', address: '', menuLink: '', menuButtonText: '' }
