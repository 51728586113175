import React from 'react'
import { FreeButton } from 'react-ui-scaffold'
import useQuestions from '../../../hooks/useQuestions/useQuestions'
import Panel from '../../Panel/Panel'

export default function HasSymptoms() {
	
	const { next } = useQuestions()

	return (
		<Panel className='DoubleVaccinated'>
			<div className="content">
				<h3>Have you experienced any of the following symptoms within the past 3 days?</h3>
				<p>
					Dry cough, sore throat, fever, headache, chest pain, shortness of breath, fatigue, lack of taste and/or smell.
				</p>
			</div>

			<div className="controls yesNo">
				<FreeButton
					className='positive'
					onClick={() => next(true)}
				>Yes</FreeButton>

				<FreeButton
					className='negative'
					onClick={() => next(false)}
				>No</FreeButton>
			</div>
		</Panel>
	)
}
