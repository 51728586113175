import React from 'react'
import Panel from '../../Panel/Panel'

export default function Rejected() {
	return (
		<Panel className='Rejected'>
			<h2>{"We're Sorry"}</h2>
			<p>
				{"For the safety and well-being of the staff and guests, you won't be able to enter the venue."}
			</p>
			
			<p>
				{'We highly suggest that you book a COVID-19 test, or self-isolate at home for a minimum of 10 days.'}
			</p>
		</Panel>
	)
}
